function CartItemApi(api_host) {
    this.api_host = api_host || location.origin;
}

CartItemApi.prototype = {
    getItem: function(cart_item_id, callback, errorHandler) {
        var url = this.api_host + "/carts/items/" + cart_item_id;
        $.get(url, callback).fail(errorHandler);
    },

    delete: function(cart_item_id, callback, errorHandler) {
        var url = this.api_host + "/carts/items/" + cart_item_id + "/delete";
        var params = {
            _method: "DELETE",
            cart_item_id: cart_item_id
        };

        $.post(url, params, callback).fail(errorHandler);
    },

    duplicate: function(cart_item_id, callback, errorHandler) {
        var url = this.api_host + "/carts/items/" + cart_item_id + "/duplicate";
        var params = {
            _method: "PUT",
            cart_item_id: cart_item_id
        };

        $.post(url, params, callback).fail(errorHandler);
    },

    updateCutId: function(cart_item_id, cut_id, callback, errorHandler) {
        var url = this.api_host + "/carts/items/" + cart_item_id + "/update-cut-id";
        var params = {
            cut_id: cut_id,
            _method: "PUT"
        };

        $.post(url, params, callback).fail(errorHandler);
    },

    updateStyleId: function(cart_item_id, style_id, callback, errorHandler) {
    	var url = this.api_host + "/carts/items/" + cart_item_id + "/update-style-id";
    	var params = {
    		style_id: style_id,
    		_method: "PUT"
    	};

    	$.post(url, params, callback).fail(errorHandler);
    },

    updateStyleUrl: function(cart_item_id, style_url, callback, errorHandler) {
        var url = location.origin + "/carts/items/" + cart_item_id + "/update-style-url";
        var params = {
            style_url: style_url,
            _method: "PUT",
        };

        $.post(url, params, callback).fail(errorHandler);
    },

    updateDesign: function(cart_item_id, design_id, builder_customization, callback, errorHandler) {
        var url = this.api_host + "/carts/items/" + cart_item_id + "/update-design";
        var params = {
            design_id: design_id,
            builder_customization: builder_customization,
            _method: "PUT"
        };

        $.post(url, params, callback).fail(errorHandler);
    },

    updateThumbnails: function(cart_item_id, thumbnails, callback, errorHandler) {
        if (thumbnails === null) {
            thumbnails = {};
        }

        front = thumbnails.front !== undefined ? thumbnails.front : "";
        back = thumbnails.back !== undefined ? thumbnails.back : "";
        left = thumbnails.left !== undefined ? thumbnails.left : "";
        right = thumbnails.right !== undefined ? thumbnails.right : "";
        topView = thumbnails.top !== undefined ? thumbnails.top : "";

        var url = this.api_host + "/carts/items/" + cart_item_id + "/update-thumbnails";
        var params = {
            front_image: front,
            back_image: back,
            left_image: left,
            right_image: right,
            top_image: topView,

            _method: 'PUT',
        };

        $.post(url, params, callback).fail(errorHandler);
    },

    updateSignature: function(cart_item_id, imageUrl, callback, errorHandler) {
        var url = this.api_host + "/carts/items/" + cart_item_id + "/update-signature-image";
        var params = {
            signature_image: imageUrl,
            _method: "PUT"
        };

        $.post(url, params, callback).fail(errorHandler);
    },

    addItem: function(customizer_data, callback, errorHandler){
        var url = this.api_host +"/carts/items/add";
        var params = {
            user_id: customizer_data.userId,
            style_id: customizer_data.styleId,
            style_name: customizer_data.styleName,
            design_id: customizer_data.designId,
            style_url: customizer_data.styleUrl,
            front_image: customizer_data.frontImage,
            back_image: customizer_data.backImage,
            left_image: customizer_data.leftImage,
            right_image: customizer_data.rightImage,
            top_image: customizer_data.topImage,
            builder_customization: JSON.stringify(customizer_data.builderCustomizations)
        };

        $.post(url, params, callback).fail(errorHandler);
    },

    markAsPendingApproval: function(cart_item_id, callback, errorHandler) {
        var url = this.api_host + "/carts/items/" + cart_item_id + "/pending-approval";
        var params = {
            _method: "PUT"
        };

        $.post(url, params, callback).fail(errorHandler);
    },

    markAsIncomplete: function(cart_item_id, callback, errorHandler) {
        var url = this.api_host + "/carts/items/" + cart_item_id + "/incomplete";
        var params = {
            _method: "PUT"
        };

        $.post(url, params, callback).fail(errorHandler);
    },

    markAsApproved: function(cart_item_id, callback, errorHandler) {
        var url = this.api_host + "/carts/items/" + cart_item_id + "/approved";

        var d = new Date();
        var approved_at = d.getFullYear() + "-" + (d.getMonth() + 1) + "-" + d.getDate() + " " + d.getHours() + "-" + d.getMinutes() + "-" + d.getSeconds();

        var params = {
            _method: "PUT",
            approved_at: approved_at
        };

        $.post(url, params, callback).fail(errorHandler);
    },

    /**
     * Save client information
     *
     * @param  {int} cart_item_id
     * @param  {object} data {
     *    school_name: "",
     *    first_name: "",
     *    note: ""
     * }
     * @param  {function} callback
     * @param  {function} errorHandler
     * @return {void}
     */
    saveClientInfo: function(cart_item_id, data, callback, errorHandler){
        var url = this.api_host + "/carts/items/" + cart_item_id + "/update-client-information";
        var params ={
            cart_item_id: cart_item_id,
            _method: "PUT",

            school_name: data.school_name,
            first_name: data.first_name,
            email: data.email,
            note: data.note,
            sales_rep_email: data.sales_rep_email,
        };

        $.post(url, params, callback).fail(errorHandler);
    },

    updateApplicationSizes: function(cart_item_id, sizes, callback, errorHandler) {
        var url = this.api_host + "/carts/items/" + cart_item_id + "/update-application-size";

        var params = {
            application_size: JSON.stringify(sizes),
            _method: "PUT",
        };

        $.post(url, params, callback).fail(errorHandler);
    },

    getSavedDesign: function(_url, responseHandler, errorHandler) {
        $.ajax({
            url: _url,
            dataType: "json",
            type: "GET",
            crossDomain: true,
            contentType: 'application/json',
            headers: {"accessToken": null},
            success: responseHandler,
            error: errorHandler
        });
    },

    updateSaveDesign: function(_url, savedDesignInformation, responseHandler, errorHandler) {
        $.ajax({
            url: _url,
            dataType: "json",
            type: "POST",
            data: JSON.stringify(savedDesignInformation),
            crossDomain: true,
            contentType: 'application/json',
            headers: {"accessToken": null},
            success: responseHandler,
            error: errorHandler
        });
    },

    changeRequested: function(cart_item_id, callback, errorHandler) {
        var url = this.api_host + "/carts/items/" + cart_item_id + "/change-requested";
        $.get(url, callback).fail(errorHandler);
    },

    changeLogs: function(cart_item_id, callback, errorHandler) {
        var url = this.api_host + "/carts/items/" + cart_item_id + "/changes-logs?sort=desc";
        $.get(url, callback).fail(errorHandler);
    },

    fixChanges: function(cart_item_id, callback, errorHandler){
        var url = this.api_host + "/carts/items/" + cart_item_id + "/fix-changes";

        var d = new Date();
        var created_at = d.getFullYear() + "-" + (d.getMonth() + 1) + "-" + d.getDate() + " " + d.getHours() + ":" + d.getMinutes() + ":" + d.getSeconds();

        var params = {
            _method: "PUT",
            created_at: created_at
        };

        $.post(url, params, callback).fail(errorHandler);
    },

    coachInfo: function(data, callback, errorHandler){
        var url = this.api_host + "/approval/coach-info?approval_token=" + data;
        $.get(url, callback).fail(errorHandler);
    },

    /**
     * Send email with coach approval link
     *
     * @param  {object} params {
     *  to: "",
     *  recipient_name: "",
     *  design_id: "",
     *  line_item_id: "",
     *  link: ""
     * }
     * @param  {Function} callback
     * @param  {Function} errorCallback
     * @return void
     */
    sendApprovalEmail: function(data, email_token, callback, errorCallback) {
        var emailConfig = {
            subject: "Approval Link",
            from: "noreply@prolook.com", // window.global_vars.sales_rep_email
        };

        var params = $.extend({}, emailConfig, data);

        $.ajax({
            url: this.api_host + "/approval/mail",
            type: "POST",
            dataType: 'JSON',
            data: JSON.stringify(params),

            headers: {
                Authorization: "Bearer " + email_token,
                "Content-Type": "application/json"
            },

            success: callback,
            error: errorCallback
        });
    },

    /**
     * Send email to sales rep
     *
     * @param  {object} params {
     *  to: "",
     *  recipient_name: "",
     *  school: ""
     * }
     * @param {string} email_token  Token came from 'approval/email-token' endpoint
     * @param {Function} callback
     * @param {Function} errorCallback
     * @return void
     */
    sendEmailToSalesRep: function(data, email_token, callback, errorCallback) {
        var emailConfig = {
            subject: "Artwork Approval",
            from: "noreply@prolook.com", // "window.global_vars.coach_email"
        };

        var params = $.extend({}, emailConfig, data);

        $.ajax({
            url: this.api_host + "/approval/mail/rep",
            type: "POST",
            dataType: 'JSON',
            data: JSON.stringify(params),

            headers: {
                Authorization: email_token,
                "Content-Type": "application/json"
            },

            success: callback,
            error: errorCallback
        });
    },

    shareDesignViaEmail: function(url, data, responseHandler, errorHandler) {
        $.ajax({
            url: url,
            dataType: "json",
            type: "POST",
            data: JSON.stringify(data),
            crossDomain: true,
            contentType: 'application/json',
            headers: {"accessToken": null},
            success: responseHandler,
            error: errorHandler
        });
    },

    getApprovalItemList: function(cart_id, callback, errorHandler) {
        var url = this.api_host + "/carts/" + cart_id + "/items/for-approval";
        $.get(url, callback).fail(errorHandler);
    },

    saveForLater: function(cart_item_id, callback, errorHandler) {
        var url = this.api_host + "/carts/items/" + cart_item_id + "/save-for-later";
        var params ={
            _method: "PUT"
        };

        $.post(url, params, callback).fail(errorHandler);
    },

    moveToCart: function(cart_item_id, cart_id, callback, errorHandler) {
        var url = this.api_host + "/carts/items/" + cart_item_id + "/move-to-cart";
        var params = {
            cart_id: cart_id,
            _method: "PUT"
        };

        $.post(url, params, callback).fail(errorHandler);
    },

    moveDesignToCart: function(params, callback, errorHandler) {
        var url = this.api_host + "/carts/items/saved-design-to-cart";
        $.post(url, params, callback).fail(errorHandler);
    },

    setActiveCartItem: function(cart_item_id, callback, errorHandler) {
        var url = this.api_host + "/carts/items/" + cart_item_id + "/set-active-cart-item";
        var params = {
            _method: "POST",
            cart_item_id: cart_item_id
        };
    
        $.post(url, params, callback).fail(errorHandler);
    }
};
